<template>
  <div>
    <!-- <Sidebar /> -->
    <div class="mt-7 pt-7 ps-2">
      <HeaderPage />
      <!-- <UserName /> -->
    </div>
    <div style="margin-bottom: 5em">
      <!-- <HeaderNavigasi /> -->
      <v-card
        style="
          padding: 0px;
          margin: 0px;
          background-color: #e1dddd;
          border-radius: 0px;
        "
        flat
      >
        <div class="ps-2">
          <p class="py-2 d-flex align-center" style="text-transform: none">
            <span
              class="pe-3"
              style="
                height: 30px;
                display: inline-block;
                border-left: 8px solid #ff0090;
              "
            ></span>
            {{ categoryName }}
          </p>
        </div>
      </v-card>
      <div>
        <div class="d-flex flex-row ps-0">
          <div class="ml-2 ps-0">
            <v-breadcrumbs
              :items="items"
              large
              class="pt-0 ps-0 breadcrumb grey--text"
              style="color: grey"
            ></v-breadcrumbs>
          </div>
        </div>
      </div>
      <!-- {{itemCategory}} -->
      <div v-if="isLoading">
        <v-container>
          <v-row style="overflow-x: auto; flex-wrap: nowrap" class="row-scroll">
            <v-col
              v-for="i in 10"
              cols="4"
              md="2"
              lg="2"
              sm="2"
              :key="i"
              style="padding: 0px 4px 0px 4px"
            >
              <Skeleton />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-else>
        <div v-if="itemCategory.length == 0" class="text-center mt-4 mb-4">
          {{ $t("message.no-product") }}
        </div>
        <div class="ps-2 pe-1">
          <v-row class="text-center p-0 m-0" no-gutters>
            <v-col
              v-for="(item, i) in itemCategory"
              :key="i"
              cols="4"
              md="2"
              lg="2"
              sm="2"
              align-self="center"
              class="pe-1 mb-4"
            >
              <Product :data="item" @data-slug="getModal" />
            </v-col>
          </v-row>
          <DetailModal
            :item_slug="slug"
            :showDialog="dialog"
            @get-modal="handleModal"
            @dialogVisible="dialog"
          />

          <ConfirmationProduct
            :data_product="data_dialog_confirm"
            :showDialog="dialog_confirmation_product"
            @close="getCloseModal"
            @dialogVisible="dialog_confirmation_product"
          />

          <Warning
            :openDialog="dialog_warning"
            @handle-dialog="handleDialog_warning"
          />
        </div>
      </div>
      <template>
        <div class="text-start">
          <v-pagination
            color="#ff0090"
            v-model="page"
            :length="last_page"
            class="mt-3"
            @input="changePage"
            :total-visible="9"
          ></v-pagination>
        </div>
      </template>
      <div class="d-flex justify-end">
        <v-btn
          color="transparent"
          class="me-2 black--text"
          depressed
          @click="$router.go(-1)"
        >
          <v-icon left dark> mdi-chevron-left </v-icon>
          {{ $t("message.back") }}
        </v-btn>
      </div>
      <CategoryFooter />
    </div>
    <Cashier class="" />
    <Sidebar />
  </div>
</template>

<script>
import User from "../components/User.vue";
import HeaderNavigasi from "../components/HeaderNavigasi.vue";
import Skeleton from "../components/skeleton/Skeleton.vue";
import Sidebar from "../components/developmentv2/Sidebar.vue";
import Product from "../components/Product.vue";
import Cashier from "../components/Cashier.vue";
import CategoryFooter from "../components/CategoryFooter.vue";
import HeaderPage from "../components/developmentv2/HeaderPage.vue";
import DetailModal from "../components/DetailModal.vue";
import ConfirmationProduct from "../components/ConfirmationProduct.vue";
import Warning from "../components/Warning.vue";
export default {
  name: "itemCategory",
  data() {
    return {
      isLoading: true,
      page: 1,
      isMounted: false,
      slug: "",
      dialog: false,
      dialog_confirmation_product: false,
      data_dialog_confirm: {},
      data_dialog_warning: null,
      dialog_warning: false,
    };
  },
  components: {
    UserName: User,
    Sidebar,
    Skeleton,
    CategoryFooter,
    Product,
    HeaderNavigasi,
    Cashier,
    HeaderPage,
    DetailModal,
    ConfirmationProduct,
    Warning,
  },
  mounted() {
    this.initiatingpage();
  },
  methods: {
    getModal(data) {
      if (typeof data === "string") {
        this.slug = data;
        this.dialog = true;
      } else {
        this.data_dialog_confirm = data;
        this.dialog_confirmation_product = true;
      }
    },
    handleModal(data) {
      if (data[0] == "ConfirmProduct") {
        this.data_dialog_confirm = data[1];
        this.dialog_confirmation_product = true;
      } else if (data[0] == "dialog_alcohol") {
        this.data_dialog_warning = data[1];
        this.dialog_warning = true;
      } else {
        this.dialog = false;
      }
    },
    getCloseModal() {
      this.dialog = false;
      this.dialog_confirmation_product = false;
    },
    handleDialog_warning(val) {
      if (val.selected == "yes") {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.data_dialog_warning.id,
          storeId: this.data_dialog_warning.store_id,
          message: {
            title: this.message.title,
          },
        });
        setTimeout(() => {
          this.dialog = false;
        }, 1800);
      }
      this.dialog_warning = false;
    },
    async dispatchfetchStoreNeeds() {
      this.isLoading = true;
      let slug = this.$router.history.current.params.slug;
      let pageParams = this.$router.history.current.params.page;
      this.slug_child = slug;
      let data = await this.$store.dispatch("itemList_module/itemByCategory", {
        slug: slug,
        page: pageParams,
      });
      // this.page = this.$store.state.itemList_module.page
      // this.page = this.$store.state.itemList_module.pageState;
      this.isLoading = false;
      return data;
    },
    initiatingpage() {
      let page = parseInt(this.$route.params.page);
      this.page = page;
    },
    async changePage() {
      this.isLoading = true;
      window.scrollTo(0, 0);
      let slug = this.$router.history.current.params.slug;
      this.slug_child = slug;

      this.$router.push({ name: "itemCategory", params: { page: this.page } });

      let res = await this.$store.dispatch("itemList_module/itemByCategory", {
        page: this.page,
        slug: slug,
      });
      this.isLoading = false;
      return res;
    },
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  created() {
    this.dispatchfetchStoreNeeds();
    // this.$router.replace({ query: { page: this.page } }).catch( () => {});
  },
  computed: {
    paramsPage() {
      let page = parseInt(this.$route.params.page);
      return page;
    },
    itemCategory() {
      return this.$store.state.itemList_module.dailyCategoryChild;
    },
    last_page() {
      return this.$store.state.itemList_module.lastPage;
    },
    categoryName() {
      return this.$store.state.itemList_module.categoryName;
    },
    items() {
      return [
        {
          text: this.$t("message.title-home"),
          disabled: false,
          href: `${process.env.BASE_URL}`,
        },
        {
          text: this.categoryName,
          disabled: true,
          href: `${process.env.BASE_URL}category-top`,
        },
      ];
    },
  },
};
</script>

<style>
.text_title {
  /* border-left: 10px solid #ff0090; */
  font-size: 18px;
}
.breadcrumb a {
  font-size: 12px !important;
  color: grey !important;
}
/* .v-application a {
  color: grey !important;
  font-size: 12px !important;
} */
</style>
